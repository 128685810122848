import { datadogRum } from "@datadog/browser-rum"
import { datadogLogs } from "@datadog/browser-logs"
import { appConfig } from "./config"

interface Logger {
	/**
	 *
	 * @param message message to log
	 * @param args any relevant arguments related to the message
	 */
	rumError(message: string, ...args: any[]): void
	/**
	 *
	 * @param message message to log
	 * @param args any relevant arguments related to the message
	 */
	rumAction(action: string, ...args: any[]): void
	/**
	 *
	 * @param message message to log
	 * @param args any relevant arguments related to the message
	 */
	info(message: string, ...args: any[]): void
	/**
	 *
	 * @param message message to log
	 * @param args any relevant arguments related to the message
	 */
	error(message: string, ...args: any[]): void
	/**
	 *
	 * @param message message to log
	 * @param args any relevant arguments related to the message
	 */
	warn(message: string, ...args: any[]): void

	/**
	 * This function will start the logger session and record events
	 */
	startSession(): void
}

class GenericLogger implements Logger {
	rumError(message: string, ...args: any[]) {
		console.log("RUM is not enabled", message, ...args)
	}

	rumAction(action: string, ...args: any[]) {
		console.log("RUM is not enabled", action, ...args)
	}

	info(message: string, ...args: any[]) {
		console.info(message, ...args)
	}

	error(message: string, ...args: any[]) {
		console.error(message, ...args)
	}

	warn(message: string, ...args: any[]) {
		console.warn(message, ...args)
	}

	startSession(): void {
		console.log("Generic logger does not record sessions")
	}
}

class DataDogLogger implements Logger {
	private static instance: DataDogLogger
	private isProduction: boolean = false
	constructor() {
		this.isProduction = appConfig.stage === "prod"
		datadogRum.init({
			applicationId: appConfig.datadogAppId!,
			clientToken: appConfig.datadogClientToken!,
			site: appConfig.monitoringEndpoint,
			service: appConfig.service,
			env: appConfig.stage,
			sessionSampleRate: 100,
			sessionReplaySampleRate: 100,
			trackUserInteractions: true,
			defaultPrivacyLevel: "mask-user-input",
			//monitor all user interactions with all domains
			allowedTracingUrls: [".*"],
		})

		datadogLogs.init({
			clientToken: appConfig.datadogClientToken!,
			site: appConfig.monitoringEndpoint,
			env: appConfig.stage,
			service: appConfig.service,
			forwardErrorsToLogs: true,
			//remove this line to stop DD from monitoring local logs
			forwardConsoleLogs: "all",
			sessionSampleRate: 100,
		})
	}

	startSession(): void {
		datadogRum.startSessionReplayRecording()
	}

	rumError(message: string, ...args: any[]) {
		if (!this.isProduction) {
			console.error(message, ...args)
		}
		datadogRum.addError(message, ...args)
	}

	rumAction(action: string, ...args: any[]) {
		if (!this.isProduction) {
			console.error(action, ...args)
		}
		datadogRum.addAction(action, ...args)
	}

	info(message: string, ...args: any[]) {
		if (!this.isProduction) {
			console.info(message, ...args)
		}
		datadogLogs.logger.info(message, ...args)
	}
	error(message: string, ...args: any[]) {
		if (!this.isProduction) {
			console.error(message, ...args)
		}
		datadogLogs.logger.error(message, ...args)
	}
	warn(message: string, ...args: any[]) {
		if (!this.isProduction) {
			console.warn(message, ...args)
		}
		datadogLogs.logger.warn(message, ...args)
	}
}

let logger: Logger | undefined

if (!logger) {
	logger = appConfig.enableDatadog === 'true' ? new DataDogLogger() : new GenericLogger()
}

export default logger!
