import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { PayLinkData, RootStore } from "../../../utils/stores"
import { useNavigate, useParams } from "react-router-dom"
import {
	PlatformEligibilityConfig,
	createTransaction,
	getEligiblePaymentMethods,
	getTransaction,
	wixCancelOrder,
} from "../../../utils/requests"
import { StorageKeys, StorageTypes, setLocalStorageData } from "../../../utils/storage"
import { ResponseCode } from "@ikhokha/commons-ecomm/dist/types/Enum"
import { FeatureFlags, PlatformName, generateErrorMessage } from "../../../utils"
import Logger from "../../../utils/logger"
import { IButtonClickEventAttributes, IPageViewEventAttributes, mxPanelEventName } from "../../../utils/mxPanel"
import { EcommMetrics } from "../../../utils/metrics"
import Loader from "../../components/loader/index"
import { PageRoutes } from "../../../utils/routes"
import Layout from "../../components/layout/Layout"
import GooglePayButton from "@google-pay/button-react"
import { Avatar, Box, Divider, Stack, Typography, useMediaQuery } from "@mui/material"
import { theme } from "@ikhokha/commons-ui/build/dist/cjs"
import { appConfig } from "../../../utils/config"
import CcIcon from "../../../assets/images/CreditCard-new.svg"
import EftIcon from "../../../assets/images/EFT.svg"
import Panel from "../../components/panel/panel"
import ErrorIcon from "../../../assets/images/icon-error.svg"
import ReturnToOrder from "../../components/returnToOrder"
import threeDSLogo from "../../../assets/images/3ds_logo.svg"
import pciLogo from "../../../assets/images/pci-logo-v2.svg"
import TradeIcon from "../../../assets/images/trade-icon.svg"
import { formatCurrency, replaceLastSegmentWithHash } from "../../../utils/utils"
import CardPaymentPage from "src/mui/components/cardpayment/CardPaymentPage"
import { InformationPanel } from "src/mui/components/accordion/InformationPanel"
// import RemoteStore from "cnp_client/RemoteStore"

// const RemoteApp = React.lazy(() => import("cnp_client/Remote"))
type PaymentMethodPageProps = {
	rootStore: RootStore
}

const PaymentIcon: Record<string, string> = {
	"/card": CcIcon,
	"/ift": EftIcon,
}

type PaymentOption = "payment-cnp" | "payment-ift"

type DisplayInformationDetails = {
	payLinkData: PayLinkData
	displayContactDetails: any
	displayDetails: Function
	isMobileView: Boolean
}

const LoaderThatRedirects: React.FunctionComponent<{ success: boolean; message: string }> = ({
	success,
	message = "Redirecting...",
}) => {
	const navigate = useNavigate()
	useEffect(() => {
		setTimeout(() => {
			const redirectPage = success ? PageRoutes["Success"] : PageRoutes["Failure"]
			navigate(redirectPage())
		}, 500)
	})
	return <Loader message={message} />
}

// Define a loading indicator component

const PaymentMethodPage: React.FunctionComponent<PaymentMethodPageProps> = observer(
	({ rootStore: { payLinkDataStore, eventsTracker } }) => {
		const rootStore = new RootStore()
		const { token } = useParams()
		if (!token) throw new Error("Path param :token is not defined")
		const [error, setErrorMessageState] = useState<{ useFriendly: boolean; message: string } | undefined>()
		const navigate = useNavigate()
		const [eligiblePaymentMethods, setEligiblePaymentMethods] = useState<PlatformEligibilityConfig[]>([])
		const [isLoading, setLoading] = useState<boolean>(true)
		const [payLinkData, setPayLinkData] = useState<PayLinkData | undefined>()
		const [displayCustomUi, setDisplayCustomUi] = useState<boolean>(false)
		const [selectedPaymentOption, setSelectedPaymentOption] = useState<string>("")
		const [displayContactDetails, setDisplayContactDetails] = useState<boolean>(false)
		const [consumeCnpClient, setConsumeCnpClient] = useState<boolean>(false)
		const [isProgressLoading, setProgressLoading] = useState<boolean>(false)
		const [isProgressLoadingIFT, setProgressLoadingIFT] = useState<boolean>(false)

		const setErrorMessage = (input: { useFriendly: boolean; message: string; canRetry?: boolean; subMessage?: string }) => {
			setErrorMessageState(input)
			setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorReason, input.useFriendly ? input.message : "")
			if (input.subMessage) {
				setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorSubReason, input.useFriendly ? input.subMessage : "")
			} else {
				setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorSubReason, input.useFriendly ? input.message : "")
			}
		}

		useEffect(
			() => {
				;(async () => {
					// console.log("RemoteStore: ", RemoteStore)
					//console.log("Remote: ", RemoteApp)
					window.addEventListener("3dsPreEnroll", (e: any) => {
						console.log("***************3dsPreEnroll", e.detail)
						if (e.detail.preEnroll === "failure") {
							navigate("/failure")
						} else if (e.detail.preEnroll === "success") {
							navigate("/success")
						}

						// setCnpFailure(true);
						// setConsumeCnpClient(false);
					})
					window.addEventListener("ThreeDS", (e: any) => {
						console.log("***************ThreeDS", e.detail)
						navigate("/3ds")
						// setCnpFailure(true);
						// setConsumeCnpClient(false);
					})
					setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorSubReason, "")
					setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorReason, "")
					setLocalStorageData(StorageTypes.IKPayment, StorageKeys.TryAgain, "")

					setLoading(true)
					const getEligiblePaymentResponse = getEligiblePaymentMethods({ token })
					const getPayLinkData = payLinkDataStore.getOrFetchPayLinkData(token)
					const [eligiblePaymentResponse, receivedData] = await Promise.all([getEligiblePaymentResponse, getPayLinkData])
					if (eligiblePaymentResponse.success) {
						const data = eligiblePaymentResponse.data as PlatformEligibilityConfig[]
						const paymentMethodCount = data.length
						console.log("paymentMethods: ", data)
						if (paymentMethodCount > 0) setEligiblePaymentMethods(data)
						else {
							// shouldn't happen as at 20220228 if merchant in good standing: default error message
							setErrorMessage({
								useFriendly: false,
								message: "Failed to determine payment method eligibility",
							})
						}
					} else {
						const { message } = eligiblePaymentResponse
						switch (message) {
							case ResponseCode.CNP_01: {
								setErrorMessage({
									useFriendly: true,
									message: "iKhokha prevented transaction: Prohibited profile status.",
									subMessage: "Please notify the merchant or contact iKhokha",
								})
								break
							}
							case ResponseCode.CNP_02: {
								setErrorMessage({
									useFriendly: true,
									message: "iKhokha prevented transaction: Merchant Category Code.",
									subMessage: "Please notify the merchant or contact iKhokha",
								})
								break
							}
							default: {
								setErrorMessage({
									useFriendly: false,
									message: "Failed to determine payment method eligibility",
								})
							}
						}
					}

					if (receivedData.success) {
						console.log("payLinkData: ", receivedData.data)
						const payLinkErrors = generateErrorMessage(receivedData.data)

						if (payLinkErrors) {
							Logger.error(`Error occurred while fetching paylink data: Pay Ref = ${token}. Message = ${payLinkErrors.message}`)
							setErrorMessage(payLinkErrors)
							setLoading(false)
							return
						}

						const clientId = receivedData.data?.customerEmail

						if (clientId) {
							eventsTracker.identifyUser(clientId)
						}
						const pageViewAttributes: IPageViewEventAttributes = {
							page_title: EcommMetrics.PAYMENT_METHOD_SELECTION_PAGE,
							page_url: `${window.location.href}`,
							page_referrer: window.localStorage.getItem("referrerSite") ?? "",
						}
						eventsTracker.logCustomEvent(mxPanelEventName.PAGE_VIEW, pageViewAttributes)

						payLinkDataStore.setPayRef(token)
						window.localStorage.setItem("ecommPaymentRef", token) //store the intial token to use in Try Again.
						// Only set the data if it's valid
						setPayLinkData(receivedData.data)
					} else {
						Logger.error(
							`Error occurred while fetching paylink data: Pay Ref = ${token}. Error = ${JSON.stringify(receivedData.error)}`,
						)
						setErrorMessage({
							useFriendly: true,
							message: receivedData.error.message,
						})
					}

					setLoading(false)
				})()
			},
			// Make the array empty so it only renders once
			// FIXME: Fix this in future
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[],
		)
		const onNext = async (option: PaymentOption) => {
			if (selectedPaymentOption || option) {
				Logger.info(`User selected: ${option}`)
				// setLoading(true)

				const transactionResponse = await getTransaction({ token, type: option })
				if (transactionResponse?.success) {
					console.log(option)
					const paymentUrl = transactionResponse?.data?.redirectUrl
					localStorage.setItem("paymentUrl", paymentUrl)
					const event: IButtonClickEventAttributes = {
						page_title: EcommMetrics.PAYMENT_METHOD_SELECTION_PAGE,
						page_url: `${replaceLastSegmentWithHash(paymentUrl)}`,
						button_text: selectedPaymentOption,
						date_time: new Date().toDateString(),
						event_type: mxPanelEventName.BUTTON_CLICK,
					}
					eventsTracker.logCustomEvent(mxPanelEventName.BUTTON_CLICK, event)
					window.localStorage.setItem("ecommPaymentRef", token)

					if (!transactionResponse?.data?.token || !transactionResponse?.success) {
						handleFailure(transactionResponse?.data?.responseMessage)
					}
					if (option === "payment-ift") {
						paymentUrl ? (window.location.href = paymentUrl) : handleFailure(transactionResponse?.data?.responseMessage)
					} else {
						setProgressLoading(false)
						// setLoading(false)
						console.log("transactionResponse: ", transactionResponse?.data)

						// paymentUrl ? (window.location.href = 'http://localhost:3001/57J204M8NLCCWF0') : handleFailure(transactionResponse?.data?.responseMessage)
						// setConsumeCnpClient(true);
						const updatedEligiblePaymentMethods = [...eligiblePaymentMethods]
						setEligiblePaymentMethods(
							updatedEligiblePaymentMethods.filter((paymentMethod) => paymentMethod.serviceName !== option),
						)

						localStorage.setItem("paymentRefToken", transactionResponse?.data?.token) //store token for cnp
						setConsumeCnpClient(true)
					}
				} else {
					handleFailure(transactionResponse.message)
				}
			}
		}

		const handleFailure = (errorMessage?: string) => {
			setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorReason, errorMessage || "Transaction URL Not Found")
			navigate(`/failure`)
		}

		const onPaymentOptionSelected = (option: PaymentOption) => {
			if (isProgressLoading || isProgressLoadingIFT) return
			if (option === "payment-cnp") {
				setProgressLoading(true)
			} else {
				setProgressLoadingIFT(true)
			}
			setSelectedPaymentOption(option)
			const selecedOptionName =
				option === "payment-cnp" ? EcommMetrics.CREDIT_OR_DEBIT_CARD_BUTTON : EcommMetrics.INSTANT_EFT_BUTTON
			console.log("selected payment method: ", option)
			const event: IButtonClickEventAttributes = {
				page_title: EcommMetrics.PAYMENT_METHOD_SELECTION_PAGE,
				page_url: `${replaceLastSegmentWithHash(window.location.href)}`,
				button_text: selecedOptionName,
				date_time: new Date().toDateString(),
				event_type: mxPanelEventName.BUTTON_CLICK,
			}

			eventsTracker.logCustomEvent(mxPanelEventName.BUTTON_CLICK, event)
			onNext(option)
		}

		const isMobile = useMediaQuery("(max-width:700px)")

		function displayDetails() {
			setDisplayContactDetails(!displayContactDetails)
		}

		return isLoading ? (
			<Loader />
		) : error ? (
			<LoaderThatRedirects success={false} message={""} />
		) : payLinkData ? (
			<Layout>
				<Stack
					direction={isMobile ? "column" : "row"}
					sx={{
						margin: isMobile ? "16px" : "80px",
						width: "935px",
						minHeight: "641px",
						borderRadius: "16px",
						gap: isMobile ? "24px" : "",
						backgroundColor: theme.palette.background.paper,
						overflowX: "hidden",
					}}
				>
					{isMobile === true ? (
						<DisplayInformation
							payLinkData={payLinkData}
							displayContactDetails={displayContactDetails}
							displayDetails={displayDetails}
							isMobileView={isMobile}
						/>
					) : (
						<></>
					)}
					<Box
						sx={{
							width: "100%",
							padding: isMobile ? "" : "40px",
							marginTop: !isMobile ? "40px" : "",
							paddingLeft: "20px",
							paddingRight: "20px",
							display: "flex",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<Typography
							variant="h6"
							sx={{
								color: theme.palette.text.primary,
							}}
						>
							Choose your payment method
						</Typography>

						{eligiblePaymentMethods.map((paymentMethod, index) => {
							if (paymentMethod.customUi) {
								return (
									<>
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												width: {
													lg: "100%",
													md: "100%",
													sm: "100%",
													xs: "100%",
												},
											}}
										>
											<GooglePayButton
												environment={appConfig.gpayEnvironment as google.payments.api.Environment}
												paymentRequest={{
													apiVersion: 2,
													apiVersionMinor: 0,
													allowedPaymentMethods: [
														{
															type: "CARD",
															parameters: {
																//allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"], // stub for next iteration
																allowedAuthMethods: ["PAN_ONLY"],
																allowedCardNetworks: ["MASTERCARD", "VISA"],
															},
															tokenizationSpecification: {
																type: "PAYMENT_GATEWAY",
																parameters: {
																	gateway: "ikhokha",
																	gatewayMerchantId: "BCR2DN4TZKSOL6AM",
																},
															},
														},
													],
													merchantInfo: {
														merchantId: "BCR2DN4TZKSOL6AM",
														merchantName: "ikhokha",
													},
													transactionInfo: {
														totalPriceStatus: "FINAL",
														totalPriceLabel: "Total",
														totalPrice: payLinkData.amount + "",
														currencyCode: "ZAR",
														countryCode: "ZA",
													},
												}}
												onLoadPaymentData={async (paymentRequest: any) => {
													console.log("load payment data", paymentRequest)
													console.log("load payment data = ", paymentRequest.paymentMethodData.tokenizationData.token)

													//Logger.info(`User selected: ${selectedPaymentOption}`)
													//setLoading(true)

													const event: IButtonClickEventAttributes = {
														page_title: EcommMetrics.GOOGLE_PAY,
														page_url: `${replaceLastSegmentWithHash(window.location.href)}`,
														button_text: "GPay",
														date_time: new Date().toDateString(),
														event_type: "Select               ",
														platformName: payLinkData?.platformName,
													}

													eventsTracker.logCustomEvent(mxPanelEventName.BUTTON_CLICK, event)
													setProgressLoading(true)
													//console.log("createTransaction ")
													const transactionResponse = await createTransaction({
														token,
														type: "payment-google",
														serviceData: paymentRequest.paymentMethodData.tokenizationData.token,
													})
													console.log("transactionResponse = ", transactionResponse)
													if (transactionResponse?.success) {
														if (!transactionResponse?.data?.token) {
															handleFailure(transactionResponse?.data?.responseMessage)
														}
														const paymentUrl = transactionResponse?.data?.redirectUrl

														// paymentUrl
														// 	? (window.location.href = paymentUrl)
														// 	: handleFailure(transactionResponse?.data?.responseMessage)
														const updatedEligiblePaymentMethods = [...eligiblePaymentMethods]
														setEligiblePaymentMethods(
															updatedEligiblePaymentMethods.filter(
																(paymentMethod) => paymentMethod.serviceName !== "payment-cnp",
															),
														)
														localStorage.setItem("ecommPaymentRef", token)
														localStorage.setItem("paymentUrl", paymentUrl)
														localStorage.setItem("paymentRefToken", transactionResponse?.data?.token)
														setConsumeCnpClient(true)
													} else {
														setProgressLoading(false)
														handleFailure(transactionResponse.message)
													}
													/*
											const transactionResponse = await getTransaction({ token, type: 'GPY' })
											
											if (transactionResponse?.success) {
											const gPayResponse = await updateGPayTransaction({ tokenisedData: paymentRequest.paymentMethodData.tokenizationData.token, paymentServiceToken: transactionResponse?.data?.token })
											if (gPayResponse?.success) {
												const paymentUrl = gPayResponse?.data?.redirectUrl
												paymentUrl ? (window.location.href = paymentUrl) : handleFailure(gPayResponse?.data?.responseMessage)
			
											}
											else {
												handleFailure(gPayResponse.message)
											}
											} else {
												handleFailure(transactionResponse.message)
											} */
												}}
												buttonColor="black"
												buttonSizeMode="fill"
												buttonType="pay"
												className="RadioBoxOption_radiobox_container_gpay"
												style={displayCustomUi ? {} : { display: "none" }}
												onReadyToPayChange={(result: any) => {
													//console.log("onReadyToPayChange", JSON.stringify(result))
													if (payLinkData.platformName !== PlatformName.WIX) {
														setDisplayCustomUi(result.isReadyToPay)
													} else {
														setDisplayCustomUi(false)
													}
												}}
											/>
										</Box>
										<Stack direction={"row"} className="divider-container" style={displayCustomUi ? {} : { display: "none" }}>
											<Divider
												sx={{
													height: "1px",
													flexGrow: "1",
													marginLeft: isMobile ? "6px" : "",
													// width: '133.5px'
												}}
											></Divider>
											<Typography
												variant="body1"
												sx={{
													color: theme.palette.text.secondary,
													marginLeft: "8px",
													marginRight: "8px",
												}}
											>
												Or pay using
											</Typography>
											<Divider
												sx={{
													height: "1px",
													flexGrow: "1",
													marginRight: isMobile ? "6px" : "",
													// width: '133.5px'
												}}
											></Divider>
										</Stack>
									</>
								)
							} else {
								return null
							}
						})}
						{consumeCnpClient && (
							<Box
								sx={{
									// width: "467.5px",
									// padding: "40px",
									height: "440px",
									overflow: "auto",
								}}
							>
								<Box
									sx={{
										width: "100%",
										height: "100%",
									}}
								>
									{/* <Suspense fallback={<LoadingIndicator />}>
										<RemoteApp rootStore={RemoteStore} />
									</Suspense> */}
									<CardPaymentPage rootStore={rootStore} />
								</Box>
							</Box>
						)}
						<Stack display={"flex"} direction={"column"} alignItems={"center"} width={"100%"}>
							{eligiblePaymentMethods.map((paymentMethod) => {
								if (!paymentMethod.customUi) {
									return (
										<Panel
											className={`RadioBoxOption_radiobox_container cc`}
											src={PaymentIcon[paymentMethod.destinationUrl]}
											alt={`${paymentMethod.serviceName} Icon`}
											title={paymentMethod.title}
											message={paymentMethod.blurb}
											onClick={() => {
												onPaymentOptionSelected(paymentMethod.serviceName as PaymentOption)
											}}
											key={paymentMethod.destinationUrl}
											isloading={isProgressLoading ? true : undefined}
											isProgressLoadingIFT={isProgressLoadingIFT ? true : undefined}
											servicename={paymentMethod.serviceName}
											disabled={false}
										/>
									)
								} else {
									return null
								}
							})}
						</Stack>

						{payLinkData.test && (
							<Stack direction={"row"} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
								<Avatar src={ErrorIcon} alt="Error icon" sx={{ width: "auto", height: "15px" }} />
								<Typography variant="subtitle1" sx={{ color: theme.palette.error.light, padding: "2px", margin: "7px 0px 5px" }}>
									Instant EFT cannot be used for test transactions
								</Typography>
							</Stack>
						)}
						{FeatureFlags.ShouldShowBackToOrder(payLinkData.platformName) &&
							payLinkData.platformName !== PlatformName.WOO_COMMERCE &&
							payLinkData.platformName !== PlatformName.WIX && (
								<div className="ButtonBlock_buttonBlock">
									<ReturnToOrder
										returnUrl={payLinkData.cancelUrl || "https://www.ikhokha.com/purchase-journey"}
										fromPage={EcommMetrics.PAYMENT_METHOD_SELECTION_PAGE}
										platformName={payLinkData.platformName}
										eventTracker={eventsTracker}
										pageReferrer={window.localStorage.getItem("referrerSite") || ""}
									/>
								</div>
							)}
						{/* If we are woocommerce, use the fail url*/}
						{FeatureFlags.ShouldShowBackToOrder(payLinkData.platformName) &&
							payLinkData.platformName === PlatformName.WOO_COMMERCE && (
								<div className="ButtonBlock_buttonBlock">
									<ReturnToOrder
										returnUrl={payLinkData.failUrl}
										fromPage={EcommMetrics.PAYMENT_METHOD_SELECTION_PAGE}
										platformName={payLinkData.platformName}
										eventTracker={eventsTracker}
										pageReferrer={window.localStorage.getItem("referrerSite") || ""}
									/>
								</div>
							)}
						{/* If we are wix, use the cancel url*/}
						{FeatureFlags.ShouldShowBackToOrder(payLinkData.platformName) &&
							payLinkData.platformName === PlatformName.WIX &&
							payLinkData.cancelUrl && (
								<div
									className="ButtonBlock_buttonBlock"
									onClick={() => {
										wixCancelOrder(token)
									}}
								>
									<ReturnToOrder
										returnUrl={payLinkData.cancelUrl}
										fromPage={EcommMetrics.PAYMENT_METHOD_SELECTION_PAGE}
										platformName={payLinkData.platformName}
										eventTracker={eventsTracker}
										pageReferrer={window.localStorage.getItem("referrerSite") || ""}
									/>
								</div>
							)}

						<Typography
							variant="body1"
							sx={{
								color: theme.palette.text.primary,
								marginTop: "24px",
							}}
						>
							Secure payments with iK Pay Online
						</Typography>
						<Stack marginTop={"8px"} direction={"row"}>
							<img style={{ marginRight: "8px" }} src={threeDSLogo} alt="3DS logo" />
							<img src={pciLogo} alt="PCI logo" />
						</Stack>

						{isMobile === true ? (
							<>
								<Box
									sx={{
										paddingTop: 4,
										width: "100%",
									}}
								>
									<InformationPanel
										displayContactDetails={displayContactDetails}
										displayDetails={displayDetails}
										isMobileView={isMobile}
									/>
								</Box>
							</>
						) : (
							<></>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginTop: "40px",
						}}
					>
						<Divider
							variant="fullWidth"
							orientation="vertical"
							sx={{
								height: "70%",
							}}
						></Divider>
					</Box>
					{isMobile !== true ? (
						<DisplayInformation
							payLinkData={payLinkData}
							displayContactDetails={displayContactDetails}
							displayDetails={displayDetails}
							isMobileView={isMobile}
						/>
					) : (
						<></>
					)}
				</Stack>
			</Layout>
		) : (
			<></>
		)
	},
)

const DisplayInformation: React.FC<DisplayInformationDetails> = ({
	payLinkData,
	displayContactDetails,
	displayDetails,
	isMobileView,
}) => {
	return (
		<Box
			sx={{
				marginTop: "40px",
				width: "100%",
				padding: isMobileView !== true ? "40px" : "",
				px: isMobileView === true ? "20px" : "",
				py: isMobileView === true ? "5px" : "",
			}}
		>
			<Stack direction={"row"} justifyContent={"space-between"}>
				<Typography
					variant="subtitle1"
					sx={{
						color: theme.palette.text.primary,
						fontWeight: "bold",
					}}
				>
					You are about to pay:
				</Typography>
				<Typography
					variant="subtitle1"
					sx={{
						color: theme.palette.text.primary,
						fontWeight: "bold",
					}}
				>
					R {formatCurrency(payLinkData.amount / 100)}
				</Typography>
			</Stack>
			<Stack
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					marginTop: "12px",
				}}
			>
				{payLinkData.businessLogo?.cropped ? (
					<img
						style={{
							flexShrink: 0,
							height: "75px",
							width: "75px",
							objectFit: "contain",
						}}
						src={`${process.env.REACT_APP_MY_BUSINESS_LOGO_IMAGES_CLOUDFRONT}/${payLinkData.businessLogo.cropped}`}
						alt="business logo"
					/>
				) : (
					<img
						style={{
							flexShrink: 0,
							marginRight: "16px",
						}}
						src={TradeIcon}
						alt="Arrow logo"
					/>
				)}
			</Stack>
			<Box
				className="RadioBoxOption_radiobox_container_gpay"
				sx={{
					display: "flex",
					width: "100%",
					marginTop: "16px",
					marginBottom: isMobileView !== true ? "40px" : "",
				}}
			>
				<Stack width={"100%"} direction="row" justifyContent={"center"}>
					<div>
						<Typography variant="h6" sx={{ color: theme.palette.text.primary, textAlign: "center" }}>
							{payLinkData.tradingName!}
						</Typography>
						<Typography
							variant="body1"
							sx={{
								color: theme.palette.text.primary,
								textAlign: "center",
							}}
						>
							{payLinkData.customReference}
						</Typography>
					</div>
				</Stack>
			</Box>
			{isMobileView !== true ? (
				<>
					<InformationPanel
						displayContactDetails={displayContactDetails}
						displayDetails={displayDetails}
						isMobileView={isMobileView}
					/>
				</>
			) : (
				<></>
			)}
		</Box>
	)
}

export default PaymentMethodPage
